import React from "react";
import aboutImage from "../../Assets/about.jpeg";
import "./HomeAbout.css";
const About = () => {
  return (
    <>
      <section className="about py-2">
        <div className="container">
          <div className="row d-flex align-items-center">
            <h1 className="text-center mb-4 title-Heading">
              Kanu Shree Radhe Krishna Group
            </h1>
            <h4 className="text-center mb-4 title-Head">
              Smile, Serve & Spread The Happiness !!
            </h4>

            <div className="kanuSrk">
              
              <p>
                <small>
                  हमारे अति प्रिय "श्री कनु ठाकुर जी" हमारे घर में "श्री बांके
                  बिहारी जी" के स्वरूप में विराजमान हैं। एवं "श्री बांके बिहारी
                  जी" के विग्रह रूप में "श्री राधे और कृष्ण जी" निहित हैं
                  अर्थात् "श्री बांके बिहारी जी" की छवि में ही "श्री राधे और
                  कृष्ण जी" प्राण रूपी समाय हुए हैं। एवं हमें गर्व है की हम
                  "श्री राधे" वाले हैं। ये ही हमारे अराध्य हैं और ये ही हमारे
                  जीवन का आधार हैं। इसलिए हमारे इस संगठन का नाम पड़ा "कनु श्री
                  राधे कृष्ण ग्रुप"।
                </small>
              </p>
              <p>
                <small>
                  यह केवल एक संगठन ही नही अपितु हमारे ठाकुर जी का अत्यंत प्रेम
                  है हम सभी के प्रति, जिसके लिए हम "प्रिय श्री कनु ठाकुर जी" के
                  सन्मुख हाथ जोड़कर नित नित सर को झुकातें हैं।
                </small>
              </p>
              <p>
                <small>
                  यह संगठन एक छोटा सा प्रयास है हमारा, जिसके माध्यम से हम
                  आत्म-साक्षरता, आध्यात्मिक विकास एवं सामाजिक सद्भाव के लिए इस
                  धार्मिक पथ पर चलकर एक अलौकिक अनुभव के प्रकाश को प्राप्त करने
                  की ईच्छा रखतें हैं।
                </small>
              </p>
              <p>
                <small>
                  हमारे ग्रुप की सदस्यता पूर्ण रूप से निशुल्क है एवं सदस्यता के
                  लिए किसी भी तरह का कोई राशि शुल्क नही लिया जाता।
                </small>
              </p>
              <p>
                <small>
                  हम व्यक्तिजन को आध्यात्मिक रूप से विकसित करने के लिए और उनके
                  आंतरिक आत्म को मजबूत करने के लिए कई गतिविधियों का अभ्यास करते
                  हैं। जिसके लिए हमारी एक प्रक्रिया है, जिसमे हम विश्व स्तर पर
                  "श्रीमद् भगवत गीता" के महाअध्यन का प्रचार कर रहे हैं। एवं हमे
                  प्रसन्नता है की आज दुनिया भर से लोग हमारी इस गतिविधि का हिस्सा
                  बन चुके हैं एवं वे हमारे उद्देश्य में उपयुक्त योगदान देते हैं।
                  आने वाले समय में हम यह सुनिश्चित करना चाहेंगे कि अधिक से अधिक
                  संख्या में परिवार और अन्य समूह हमारे साथ जुडें व एक जुट होकर
                  अपने हिंदू धर्मत्व के इस पवित्र ग्रंथ को बढ़ावा दें एवं अपने
                  हिंदू धर्म की संस्कृति को सक्रिय बनाए रखने का प्रयास करें।
                </small>
              </p>
              <p>
                <small>
                  {" "}
                  कृपया हमारे साथ जुड़ें और ज्यादा से ज्यादा लोगो को जुड़ने के
                  लिए प्रेरित करे।
                </small>
              </p>
              <h3 className="fs-6  text-center">
                <strong>कृपया समर्थन करते रहें !!</strong>
              </h3>
              <h2 className="fs-6  text-center">
                <strong>राधे राधे</strong>
              </h2>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
